<template>
    <div>
        <div v-if="!$common.mobile()" class="fl_c ju_c al_c">
            <div class="topTitle fl_r ju_c al_c">
                <div class="topTitleCenter fl_r ju_b">
                    <div class="topTitle_info">您好，欢迎您访问{{title[0]}}！</div>
                    <div class="topTitle_number">客服电话：{{phone}}</div>
                </div>
            </div>
            <div class="iconBox fl_r ju_b al_c">
                <div class="indexBox fl_r al_c">
                    <img class="indexImg" :src="icon" alt="">
                    <!--                    <div class="fl_c al_c" v-if="title.length>1">-->
                    <!--                        <div class="indexTitle">{{title[0]}}交易中心</div>-->
                    <!--                        <div class="indexEng">{{title[1]}}</div>-->
                    <!--                    </div>-->
                </div>
                <div class="fl_r ju_b">
                    <el-input placeholder="在这里搜索..." v-model="inputValue">
                        <el-button slot="append" icon="el-icon-search" class="buttonSty"></el-button>
                    </el-input>
                </div>
                <div class="fl_c codeImg al_c">
                    <img v-if="EWMImg!=null" class="imgBox" :src="EWMImg.image" alt="">
                    <div class="codeBottom">官方公众号</div>
                </div>
            </div>
            <div class="tabSty fl_r ju_c">
                <div class="tabItem pos_r" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)"
                     v-for="(item,index) in headerList" :key="index" @click="goPage(item)">
                    {{item.name}}
                    <div v-if="item.showSecondTab&&item.subArticles.length>0" class="pos_a"
                         style="width: 100%;height: auto;background-color:#195B40;z-index: 999">
                        <div v-for="(childItem,childIndex) in item.subArticles" :key="childIndex"
                             @click.stop="goChildPage(item,childItem)"
                             style="font-weight: lighter;font-size: 14px">
                            <div v-if="item.columnType!='01'">{{childItem.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fl_r ju_b header_height_box1" v-if="$common.mobile()">
            <img :src="icon" alt="" class="cropped-image" mode="aspectFit">
            <img src="../../assets/img/bg-list.png" alt="" @click="isMore=!isMore"
                 style="width: 40px;height: 40px;margin: 5px 20px 5px 5px">
        </div>
        <div class="select_box animate__animated animate__slideInDown" v-if="isMore" @click="isMore=false">
            <div
                    :class="['select_item', {'select_item_active' : current == item.id}]"
                    v-for="item in headerList" @click="goPage(item)"
                    :key="item.id">{{item.name}}
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getSwiper,
        getColumnTree,
        getLogo,
        imgInfoByType,
        getServiceNumber,
        getAppLogo
    } from '../../utils/api/index.js'
    import CenterTab from "../CenterTab/CenterTab";

    export default {
        name: "Header",
        components: {CenterTab},
        data() {
            return {
                isMore: false,
                current: 1,
                formalItem: [],
                headerList: [],
                columnImg: [],
                title: '',
                icon: '',
                inputValue: '',
                showSecondTab: false,
                phone: null,
                EWMImg: null,
            }
        },
        watch: {},
        mounted() {
            this.getColumnTree()
            this.imgInfoByType()
            this.getServiceNumber()
            this.getLogo()
            this.getAppLogo()
        },
        methods: {
            async getServiceNumber() {
                let res = await getServiceNumber()
                this.phone = res.data
            },
            async imgInfoByType() {
                let res = await imgInfoByType({
                    belongType: 91
                })
                this.EWMImg = res.data[0]
            },
            goPage(data) {
                if (data.name == '下载中心') {
                    return this.$router.push('downloadPage')
                }
                if (data.name == '首页') {
                    this.$router.push('/')
                } else {
                    this.$router.push({path: '/secondPage', query: {columnId: data.subArticles[0].id}})
                }
                this.$store.commit('setFormalItem', data);
                this.$store.commit('setChildItem', data.subArticles[0]);
            },
            goChildPage(data, child) {
                if (child.name == '下载地址') {
                    return this.$router.push('downloadPage')
                }
                this.$store.commit('setFormalItem', data);
                this.$store.commit('setChildItem', child);
                this.$router.push({path: '/secondPage', query: {columnId: child.id}})
            },
            mouseenter(data) {
                this.$set(data, 'showSecondTab', true)
                this.showSecondTab = true
            },
            mouseleave(data) {
                this.$set(data, 'showSecondTab', false)
                this.showSecondTab = false
            },
            async getAppLogo() {
                let res = await getAppLogo()
                const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'icon';
                link.href = res.data.image;
                document.head.appendChild(link);
            },
            async getLogo() {
                let res = await getLogo()
                this.icon = res.data.image
                this.title = res.data.title.split('/')
                document.title = this.title[0]
            },
            async getSwiper(id) {
                let res = await getSwiper({
                    columnId: id
                })

            },
            async getColumnTree() {
                let res = await getColumnTree()
                res.data.map(item => {
                    item.showSecondTab = false
                    if (item.subArticles && item.subArticles.length > 0) {
                        item.subArticles
                    }
                })
                this.headerList = res.data
            },
        },
    }
</script>

<style scoped lang="scss">
    .topTitle {
        width: 100%;
        height: 33px;
        background-color: #F2F1EF;

        .topTitleCenter {
            width: 1200px;
            height: 100%;

            .topTitle_info {
                line-height: 33px;
                font-size: 14px;
            }

            .topTitle_number {
                font: 14px/33px "微软雅黑";
                color: #E78D06;
                margin-left: 20px;
            }
        }
    }

    .iconBox {
        width: 1200px;
        height: 105px;

        .indexBox {
            width: 500px;
            height: 105px;

            .indexImg {
                width: 100%;
                height: 70%;
            }

            .indexTitle {
                width: auto;
                font-size: 30px;
                color: black;
                text-align: center;
                font-weight: bold;
            }

            .indexEng {
                width: 210px;
                font-size: 15px;
                text-transform: uppercase;
                text-align: center;
                margin-top: 10px;
                font-weight: bold;
            }
        }
    }

    .el-input {
        width: 520px;
        height: 47px;
    }

    .buttonSty {
        background-color: #195B40;
    }

    ::v-deep .el-input-group > .el-input__inner {
        height: 45px;
        margin-top: 1px;
        border: 1px solid #195B40;
    }

    ::v-deep .el-input-group__append {
        background-color: #195B40;
        border-radius: 0 5px 5px 0;
        width: 96px;
        text-align: center;
    }

    ::v-deep .el-icon-search:before {
        color: white;
    }

    .codeImg {
        width: 85px;
        height: 80px;

        .imgBox {
            width: 58px;
            height: 58px;
        }

        .imgBox:hover {
            width: 250px;
            height: 250px;
            z-index: 9;
        }

        .codeBottom {
            width: 85px;
            height: 18px;
            border-radius: 10px;
            background-color: #03920a;
            text-align: center;
            color: white;
            font-size: 10px;
            font-weight: bold;
            margin-top: 10px;
        }
    }

    .tabSty {
        width: 1200px;
        height: 43px;
        background-color: #195B40;

        .tabItem {
            width: 12.5%;
            height: 100%;
            line-height: 43px;
            color: white;
            text-align: center;
            cursor: pointer;
        }
    }

    .header_height_box1 {
        width: 100%;
        display: flex;
        z-index: 9;
        position: fixed;
        top: 0;
        height: 50px;
        background-color: white;
    }

    .select_box {
        left: 0px;
        top: 50px;
        width: 100%;
        background: #fff;
        z-index: 999;
        position: fixed;
    }

    .select_item {
        padding: 10px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 700;
        color: #333333;
        box-sizing: border-box;
    }

    .cropped-image {
        width: calc(100% - 60px);
        height: 100%;
        /*margin: 5px 0px 5px 20px*/
    }
</style>
